import { Colours } from '@/Components/Colours'
import { Pill } from '@/Components/Pill'
import { Router } from '@/Components/Router'
import { cn } from '@/Lib'
import { UniqueIdentifier } from '@dnd-kit/core'
import { FC, ReactElement } from 'react'

type StructuredLink = {
  type: 'link'
  children: ReactElement | string
  href: string
}

type StructuredSpan = {
  type: 'span'
  children: ReactElement | string
}

type StructuredError = {
  type: 'error'
  children: ReactElement | string
}

type StructuredPill = {
  type: 'pill'
  children: ReactElement | string
  colour: Colours
}

export type StructuredItem =
  | StructuredPill
  | StructuredError
  | StructuredSpan
  | StructuredLink

export type StructuredParagraphProps = {
  id: UniqueIdentifier
  body: StructuredItem[]
  className?: string
}

export const StructuredParagraph: FC<StructuredParagraphProps> = ({
  id,
  body,
  className
}) => {
  return (
    <p className={cn('w-full leading-loose', className)}>
      {body.map((item, index) => {
        if (item.type == 'span') {
          return <span key={`${id}-span-${index}`}>{item.children}</span>
        } else if (item.type == 'pill') {
          return (
            <Pill key={`${id}-pill-${index}`} colour={item.colour}>
              {item.children}
            </Pill>
          )
        } else if (item.type == 'error') {
          return (
            <span
              key={`${id}-error-${index}`}
              className='inline-block text-sm leading-normal text-error'
            >
              {item.children}
            </span>
          )
        } else if (item.type == 'link') {
          return (
            <Router
              key={`${id}-error-${index}`}
              href={item.href}
              className='text-primary'
            >
              {item.children}
            </Router>
          )
        }
      })}
    </p>
  )
}
